import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-phone-number-input/style.css'
import 'animate.css';
import TagManager from 'react-gtm-module';

// Initialize TagManager
const tagManagerArgs = {
  gtmId: 'GTM-5RH2KP7' // Replace with your GTM ID
};

TagManager.initialize(tagManagerArgs);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
