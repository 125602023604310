import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import logo1 from "./homeimages/Group 562.svg";
import logo2 from "./homeimages/Group 564.svg";
import logo3 from "./homeimages/Group 566.svg";
import logo4 from "./homeimages/Group 568.svg";
import { useNavigate } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import { useState } from "react";
function LearningPrograms({ Robowhiz }) {
  const [animated, setAnimated] = useState(false);
  const [animatedtwo, setAnimatedtwo] = useState(false);
  const [reftwo, inViewtwo] = useInView({
    triggerOnce: false, // Trigger animation each time the element comes into view
    threshold: 0.1, // Adjust this threshold as needed
  });

  // Use useEffect to update animated state when inView changes
  React.useEffect(() => {
    if (inViewtwo) {
      setAnimatedtwo(true);
    }
  }, [inViewtwo]);
  const [ref, inView] = useInView({
    triggerOnce: false, // Trigger animation each time the element comes into view
    threshold: 0.1, // Adjust this threshold as needed
  });

  // Use useEffect to update animated state when inView changes
  React.useEffect(() => {
    if (inView) {
      setAnimated(true);
    }
  }, [inView]);
  const navigate = useNavigate();

  const HandleRobowhiz = () => {
    navigate("/robotics-for-kids");
  };
  const HandleChampcoder = () => {
    navigate("/champ-coder");
  };
  const HandleGaming = () => {
    navigate("/game-development-for-kids");
  };
  const HandleMetaverse = () => {
    navigate("/artificial-intelligence-programs-for-kids");
  };
  return (
    <Container fluid className="lcswrapper">
      <Row ref={reftwo} className="learningcontent">
        <span
          className={`${
            animatedtwo ? "animate__animated animate__zoomIn" : ""
          }`}
        >
          Our Cutting-Edge <span>Learning Programs for the Future!</span>
        </span>
      </Row>
      <Row className="learningcards">
        <Col
          className={`learningcards1 ${
            animatedtwo ? "animate__animated animate__zoomIn" : ""
          }`}
        >
          <Col className="col1">
            <span className="lctitle1">Robo Whiz</span>
            <br />
            <span className="lccontent1">
              Unleash your child's inner inventor with our robotics programs.
            </span>
            <br />
            <br />
            <Button onClick={HandleRobowhiz}>Explore Now</Button>
          </Col>
          <Col className="col2">
            <img src={logo1} alt="" />
          </Col>
        </Col>
        <Col
          className={`learningcards2 ${
            animatedtwo ? "animate__animated animate__zoomIn" : ""
          }`}
        >
          <Col className="col1">
            <span className="lctitle1">Gaming Honcho</span>
            <br />
            <span className="lccontent1">
              Transform your child's love for gaming into valuable STEAM skills
            </span>
            <br />
            <br />
            <Button onClick={HandleGaming}>Explore Now</Button>
          </Col>
          <Col className="col2">
            <img src={logo2} alt="" />
          </Col>
        </Col>
        <Col
          className={`learningcards3 ${
            animatedtwo ? "animate__animated animate__zoomIn" : ""
          }`}
        >
          <Col className="col1">
            <span className="lctitle1">Champ Coder</span>
            <br />
            <span className="lccontent1">
              Discover the coding prodigy in your child through our coding
              programs.
            </span>
            <br />
            <br />
            <Button onClick={HandleChampcoder}>Explore Now</Button>
          </Col>
          <Col className="col2">
            <img src={logo3} alt="" />
          </Col>
        </Col>
        <Col
          className={`learningcards4 ${
            animatedtwo ? "animate__animated animate__zoomIn" : ""
          }`}
        >
          <Col className="col1">
            <span className="lctitle1">Metaverse Maestro</span>
            <br />
            <span className="lccontent1">
              Equip your kid with essential skills for the Metaverse world
            </span>
            <br />
            <br />
            <Button onClick={HandleMetaverse}>Explore Now</Button>
          </Col>
          <Col className="col2">
            <img src={logo4} alt="" />
          </Col>
        </Col>
      </Row>
      {Robowhiz ? (
        ""
      ) : (
        <Row className="whychoose">
          <div>
            <Row className="learningcontent">
              <span
                // className="codestitle"
                ref={ref}
                className={`codestitle ${
                  animated ? "animate__animated animate__zoomIn" : ""
                }`}
              >
                Why choose<span> Coding School</span>
              </span>
            </Row>
            <Row className="choosecards">
              <Col
                className={`choosecard ${
                  animated
                    ? "animate__animated animate__fadeInDown cstdelay200"
                    : ""
                }`}
              >
                <span className="subtitle">Top global educators</span>
                <br className="disbr" />
                <br />
                <span className="subcontent">
                  Thoroughly trained to cultivate children's interest,
                  curiosity, and learning
                </span>
              </Col>
              <Col
                className={`choosecard ${
                  animated
                    ? "animate__animated animate__fadeInDown cstdelay500"
                    : ""
                }`}
              >
                <span className="subtitle">Cutting-edge curriculum</span>
                <br className="disbr" />
                <br />
                <span className="subcontent">
                  Crafted by renowned industry experts and leading universities
                </span>
              </Col>
              <Col
                className={`choosecard ${
                  animated
                    ? "animate__animated animate__fadeInDown cstdelay800"
                    : ""
                }`}
              >
                <span className="subtitle">Getting into the global arena</span>
                <br className="disbr" />
                <br />
                <span className="subcontent">
                  Get access to our international hackathons and compete against
                  1M+ future leaders
                </span>
              </Col>
            </Row>
          </div>
        </Row>
      )}
    </Container>
  );
}

export default LearningPrograms;
