import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import image1 from "./homeimages/Group 591.png";
import { useInView } from "react-intersection-observer";
import { useState } from "react";
function DigitalDivide() {
  const [animated, setAnimated] = useState(false);
  const [ref, inView] = useInView({
    triggerOnce: false, // Trigger animation each time the element comes into view
    threshold: 0.1, // Adjust this threshold as needed
  });

  // Use useEffect to update animated state when inView changes
  React.useEffect(() => {
    if (inView) {
      setAnimated(true);
    }
  }, [inView]);
  return (
    <Container fluid className="digitalwrapper">
      <Row className="digitalrow">
        <Col 
        // className="digitalcol1"
        ref={ref}
          className={`digitalcol1 ${
            animated ? "animate__animated animate__fadeInUp" : ""
          }`}>
          <span className="dheading">
            Breaking the{" "}
            <span className="dheadsubspan">
              Digital Divide with Million Coders
            </span>
          </span>
          <br className="disbr" />
          <br />
          <span className="dcontent">
            The ‘Million Coders’ initiative by Airtics Education, in
            collaboration with Coding School, aims to empower a million young
            people with essential programming skills and help them learn to
            code, upskill coding, and re-skill for employment in the future. It
            envisions a future rich with employment opportunities for youth by
            ensuring coding accessibility. The program will offer specialized
            training to help them excel in programming and meet the demands of
            the digital economy. This, in turn, will foster innovation and
            create a positive impact.
            <br />
            <br />
            Our goal isn't just to teach code, but to light up possibilities, make sure everyone can speak the language of technology, and help people make a positive change in our digital world.
          </span>
        </Col>
        <Col className="digitalcol2">
          <img src={image1} alt="" 
          ref={ref}
          className={`${
            animated ? "animate__animated animate__fadeInUp" : ""
          }`}
          />
        </Col>
      </Row>
    </Container>
  );
}

export default DigitalDivide;
