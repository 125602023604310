import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import logo from "./commonimages/image 3.svg";
import robot from "./commonimages/Group 842.svg";
import f from "./commonimages/001-facebook.svg";
import i from "./commonimages/004-instagram.svg";
import l from "./commonimages/linkedin - icon.svg";
import t from "./commonimages/003-twitter.svg";
import elipsetop from "./commonimages/Ellipse 13.png";
import westfordadvlogo from "./commonimages/westfordventures.svg";
import "./Footer.css";
import { useInView } from "react-intersection-observer";
import { Link } from "react-router-dom";

function Footer() {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [animated, setAnimated] = useState(false);
  const [animatedtwo, setAnimatedtwo] = useState(false);
  const [ref, inView] = useInView({
    triggerOnce: false, // Trigger animation each time the element comes into view
    threshold: 0.1, // Adjust this threshold as needed
  });
  const [reftwo, inViewtwo] = useInView({
    triggerOnce: false, // Trigger animation each time the element comes into view
    threshold: 0.1, // Adjust this threshold as needed
  });
  const ismobile = screenWidth < 760;
  // Use useEffect to update animated state when inView changes
  React.useEffect(() => {
    if (inView) {
      setAnimated(true);
    }
    if (inViewtwo) {
      setAnimatedtwo(true);
    }
  }, [inView, inViewtwo]);
  // const isMobile = screenWidth < 480;

  useEffect(() => {
    function handleResize() {
      setScreenWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <Container fluid className="footerwrapper">
      <Row className="footermainrow">
        {!ismobile ? (
          <img src={elipsetop} alt="e" className="elipsetop" />
        ) : (
          <img src={elipsetop} alt="e" className="elipsetopmobile" />
        )}
        <img src={elipsetop} alt="e" className="elipsebottom" />
        <Row className="logorow">
          <img src={logo} alt="" />
        </Row>
        <Row className="columns">
          <Col
            // className="col2"
            ref={ref}
            className={`col2 ${
              animated ? "animate__animated animate__fadeInUp" : ""
            }`}
          >
            <span className="col2head">Company</span>
            <Link to="/" className="col2content">
              Home
            </Link>
            <span className="col2content">About Us </span>
            <span className="col2content">Careers</span>
            <span className="col2content">Blog</span>
            <span className="col2content">Programs</span>
            {/* <div className="colline" /> */}
          </Col>
          <Col
            className={`col3 ${
              animated ? "animate__animated animate__fadeInUp" : ""
            }`}
          >
            <span className="col2head">Our Programs</span>
            <span className="col2content">Web Developer</span>
            <span className="col2content">Game Developer</span>
            <span className="col2content">Python Developer</span>
            <span className="col2content">Code CHAMPS</span>
            {/* <div className="colline" /> */}
          </Col>
          <Col
            className={`col3 ${
              animated ? "animate__animated animate__fadeInUp" : ""
            }`}
            style={{ border: "none" }}
            id="last-col3"
          >
            <span className="col2head">Policies</span>
            <span className="col2content">Privacy Policy</span>
            <span className="col2content">Terms & Conditions</span>
            <span className="col2content">Site Map</span>
          </Col>
        </Row>
        {/* </>
        )} */}
        {/* {isMobile && (
          <>
            <Accordion defaultActiveKey="">
              <Accordion.Item eventKey="0">
                <Accordion.Header>Company</Accordion.Header>
                <Accordion.Body>
                  <span className="col2content">Home</span>
                  <br />
                  <span className="col2content">About Us </span>
                  <br />
                  <span className="col2content">Careers</span>
                  <br />
                  <span className="col2content">Programs</span>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>Programs</Accordion.Header>
                <Accordion.Body>
                  <span className="col2content">Web Developer</span>
                  <br />
                  <span className="col2content">Game Developer</span>
                  <br />
                  <span className="col2content">Python Developer</span>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>Policies</Accordion.Header>
                <Accordion.Body>
                  <span className="col2content">Privacy Policy</span>
                  <br />
                  <span className="col2content">Terms & Conditions</span>
                  <br />
                  <span className="col2content">Site Map</span>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <Row className="mobcolumns">
              <Col className="mobcol1">
                <img src={location} alt="" />
                <span>
                  Airtics Education, 7th floor Citygate Tower Al Ittihad Road,
                  Al Tawan, Sharjah, UAE.
                </span>
                <img src={call} alt="" />
                <span>+971 567 887007</span>
                <img src={mail} alt="" />
                <span>info@codingschool.ai</span>
              </Col>
            </Row>
          </>
        )} */}
        <hr />
        <Row className="fdetails">
          <img src={robot} alt="" className="robo" />
          <div className="socaillinks">
            <img src={f} alt="facebook" />
            <img src={i} alt="instagram" />
            <img src={l} alt="linkidn" />
            <img src={t} alt="twitter" />
            <img
              src="https://imagedelivery.net/5MYSbk45M80qAwecrlKzdQ/fd62f9be-08cd-472f-e917-54e0ff9d8600/public"
              alt="youtube"
              style={{ maxWidth: "25px", mixBlendMode: "lighten" }}
            />
          </div>
          <Row className="fdrow">
            <Col className="fcol1">
              <div>
                <span
                  ref={reftwo}
                  className={`${
                    animatedtwo ? "animate__animated animate__fadeInUp" : ""
                  }`}
                >
                  <span style={{ color: "#faad17" }}>UAE : </span>Coding School,
                  7th floor Citygate Tower Al Ittihad Road, Al Tawan, Sharjah,
                  UAE.
                </span>
                <br />
                <span
                  ref={reftwo}
                  className={`${
                    animatedtwo ? "animate__animated animate__fadeInUp" : ""
                  }`}
                >
                  <span>
                    Email us at{" "}
                    <span style={{ fontWeight: "700", margin: "0 5px" }}>
                      info@codingschool.ai
                    </span>{" "}
                    or Call{" "}
                    <span style={{ fontWeight: "700", margin: "0 5px" }}>
                      +971 6 745 2556
                    </span>
                  </span>
                </span>
                {/* <br /> */}
                <br />
                <span
                  className={`${
                    animatedtwo ? "animate__animated animate__fadeInUp" : ""
                  }`}
                >
                  <span style={{ color: "#faad17" }}>USA : </span>3790 EL CAMINO
                  REAL UNIT #581 PALO ALTO, CA, United States - 94306
                </span>
                <br />
              </div>
            </Col>
            <br />
            <Col className="fcol2">
              <img
                src={westfordadvlogo}
                alt=""
                // className="westfordlogo"
                className={`westfordlogo ${
                  animatedtwo ? "animate__animated animate__fadeInUp" : ""
                }`}
              />
            </Col>
            <br />
            <br />
            <span
              style={{ textAlign: "center" }}
              // ref={reftwo}
              className={` ${
                animatedtwo ? "animate__animated animate__fadeInUp" : ""
              }`}
            >
              @ 2024 coding school. All Rights Reserved
            </span>
          </Row>
        </Row>
      </Row>
    </Container>
  );
}

export default Footer;
