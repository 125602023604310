import React, { useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import caller from "./contactimg/Group 882.png";
import PhoneInput from "react-phone-number-input";
function Connect() {
  const [formData, setFormData] = useState({
    fullName: "",
    country: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleContactChange = (value) => {
    setFormData({
      ...formData,
      ContactNo: value,
    });
  };
  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent the default form submission behavior
    const formDataToSend = new FormData();
    Object.entries(formData).forEach(([key, value]) => {
      formDataToSend.append(key, value);
    });

    // Send the data to the Google Apps Script
    const response = await fetch(
      "https://script.google.com/macros/s/AKfycbzDEufIv7Z3-1GIGBWlsG7UnYJWrHLfLYhY3iWXxBgO6Kle3aCC_bNr6yWQWr_c7qk/exec",
      {
        method: "POST",
        body: formDataToSend,
      }
    );

    // Handle the response if needed
    if (response.ok) {
      // Successful submission
      alert("Form submitted successfully");
      setFormData({
        // Clear the form fields
        fullName: "",
        country: "",
        email: "",
        message: "",
      });
    } else {
      // Handle errors
      console.error("Form submission failed");
    }
  };
  return (
    <Container fluid className="connectwrapper" id="connectwrapper">
      <Row className="connectrow">
        <Col className="connectcol1">
          <img src={caller} alt="" />
        </Col>
        <Col className="connectcol2">
          <Row className="title">
            <span>Connect with us</span>
          </Row>
          <form onSubmit={handleSubmit}>
            <Row className="connectform">
              <Col className="formcol">
                <input
                  type="text"
                  name="fullName"
                  placeholder="Full Name"
                  value={formData.fullName}
                  onChange={handleChange}
                  required
                />
                <input
                  type="text"
                  name="country"
                  placeholder="Country"
                  value={formData.country}
                  onChange={handleChange}
                  required
                />
                {/* <input type="text" placeholder='Country'/> */}
              </Col>
              <Col className="formcol">
                <input
                  type="text"
                  name="email"
                  placeholder="E-Mail"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
                <PhoneInput
                  placeholder="Phone number"
                  id="contact"
                  name="ContactNo"
                  className="phoneinput"
                  defaultCountry="AE"
                  value={formData.ContactNo}
                  onChange={handleContactChange}
                  required
                />
                {/* <input type="text" placeholder='Mobile Number'/> */}
              </Col>
              <Row className="formmsg">
                <input
                  type="text"
                  name="message"
                  placeholder="Message"
                  value={formData.message}
                  onChange={handleChange}
                />
              </Row>
            </Row>
            <Row className="connectsubmit">
              <Button type="submit">Submit </Button>
            </Row>
          </form>
        </Col>
      </Row>
    </Container>
  );
}

export default Connect;
