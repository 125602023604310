import React, { useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
// import axios from 'axios';
// import caller from "./homeimages/IMAGE 4 1.svg";
import caller from "./homeimages/IMAGE 4 1.png";
import PhoneInput from "react-phone-number-input";
import { useInView } from "react-intersection-observer";
function SpeakToTeam() {
  const [formData, setFormData] = useState({
    fullName: "",
    country: "",
    email: "",
  });
  const [animated, setAnimated] = useState(false);
  const [ref, inView] = useInView({
    triggerOnce: false, // Trigger animation each time the element comes into view
    threshold: 0.1, // Adjust this threshold as needed
  });

  // Use useEffect to update animated state when inView changes
  React.useEffect(() => {
    if (inView) {
      setAnimated(true);
    }
  }, [inView]);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleContactChange = (value) => {
    setFormData({
      ...formData,
      ContactNo: value,
    });
  };
  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent the default form submission behavior

    const formDataToSend = new FormData();
    Object.entries(formData).forEach(([key, value]) => {
      formDataToSend.append(key, value);
    });
    // Send the data to the Google Apps Script
    const response = await fetch(
        "https://script.google.com/macros/s/AKfycbzDEufIv7Z3-1GIGBWlsG7UnYJWrHLfLYhY3iWXxBgO6Kle3aCC_bNr6yWQWr_c7qk/exec",
      {
        method: "POST",
        body: formDataToSend,
      }
    );

    // Handle the response if needed
    if (response.ok) {
      // Successful submission
      alert("Form submitted successfully");
      setFormData({ // Clear the form fields
        fullName: "",
        country: "",
        email: "",
      });
    } else {
      // Handle errors
      console.error("Form submission failed");
    }
  };
  return (
    <Container fluid className="speakwrapper" id="speakToTeamSection">
      <Row className="speakrow">
        <Col className="speakcol1">
          <img src={caller} alt="" 
          className={`${
            animated ? "animate__animated animate__zoomIn" : ""
          }`}
          />
        </Col>
        <Col className="speakcol2">
          <Row className="title">
            <span 
            ref={ref}
            className={`${
              animated ? "animate__animated animate__zoomIn" : ""
            }`}
            >Speak to our Team</span>
          </Row>
          <form onSubmit={handleSubmit}>
            <Row className="form">
              <Col className="formcol">
                <input
                  type="text"
                  name="fullName"
                  placeholder="Full Name"
                  value={formData.fullName}
                  onChange={handleChange}
                  required
                />
                <input
                  type="text"
                  name="country"
                  placeholder="Country"
                  value={formData.country}
                  onChange={handleChange}
                  required
                />
              </Col>
              <Col className="formcol">
                <input
                  type="text"
                  name="email"
                  placeholder="E-Mail"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
                <PhoneInput
                    placeholder="Phone number"
                    id="contact"
                    name="ContactNo"
                    className="phoneinput"
                    defaultCountry="AE"
                    value={formData.ContactNo}
                    onChange={handleContactChange}
                    required
                  />
              </Col>
            </Row>
            <Row className="speaksubmit">
              <button type="submit" variant="warning">
                Submit
              </button>
            </Row>
          </form>
        
        </Col>
      </Row>
    </Container>
  );
}

export default SpeakToTeam;
