import React, { useEffect, useRef, useState } from "react";
import { Button, Container, Nav, Navbar } from "react-bootstrap";
import "./Navbar.css";
import Logo from "./commonimages/image 2.svg";
import Toggle from "./commonimages/Group 435.svg";
import arrow from "./commonimages/Vector (10).svg";
import MyContactForm from "../reusable/MyContactForm";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
function NavBar() {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const collapseRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [robo, setRobo] = useState(false);
  const [champ, setChamp] = useState(false);
  const [gaming, setGaming] = useState(false);
  const [metev, setMetev] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const location = useLocation();

  const isHome = location.pathname === "/";
  const isCs = location.pathname === "/why-coding-school";
  const isContactUS = location.pathname === "/contact-us";
  const isOurProgram =
    location.pathname === "/robotics-for-kids" ||
    location.pathname === "/game-development-for-kids" ||
    location.pathname === "/champ-coder" ||
    location.pathname === "/artificial-intelligence-programs-for-kids";

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const RhoboDropdownHandler = () => {
    setRobo(!robo);
    setChamp(false);
    setGaming(false);
    setMetev(false);
    // setBachelor(false);
  };
  const GamingDropdownHandler = () => {
    setRobo(false);
    setChamp(false);
    setGaming(!gaming);
    setMetev(false);
    // setBachelor(false);
  };
  const ChampDropdownHandler = () => {
    setRobo(false);
    setChamp(!champ);
    setGaming(false);
    setMetev(false);
    // setBachelor(false);
  };
  const MetevDropdownHandler = () => {
    setRobo(false);
    setChamp(false);
    setGaming(false);
    setMetev(!metev);
    // setBachelor(false);
  };

  const openModal = () => {
    setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(false);
  };
  const Modal = ({ onClose, videoUrl }) => {
    return (
      <ModalWrapper>
        <ModalContent>
          <MyContactForm popup={true} />
          <CloseButton onClick={onClose}>&times;</CloseButton>
        </ModalContent>
      </ModalWrapper>
    );
  };
  const hideBars = () => {
    if (collapseRef.current) {
      collapseRef.current.setAttribute("class", "collapse navbar-collapse");
    }
  };

  const isMobile = screenWidth < 480;
  const istoggle = screenWidth < 992;
  const iscollapsenav = screenWidth < 1040;

  const navcollpase = !istoggle && iscollapsenav;

  // Handle the click event of the "Book Free Class" button
  const handleBookFreeClassClick = () => {
    const speakToTeamSection = document.getElementById("speakToTeamSection");
    if (speakToTeamSection) {
      speakToTeamSection.scrollIntoView({ behavior: "smooth" });
    }
  };
  useEffect(() => {
    // Function to update screen width in state
    function handleResize() {
      setScreenWidth(window.innerWidth);
    }

    // Add an event listener to update screen width on window resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Navbar expand="lg" className="bg-body-tertiary" collapseOnSelect>
      <Container style={{ maxWidth: "1200px" }}>
        {isOpen && <Modal onClose={closeModal} />}

        {!isMobile && (
          <>
            {/* {isOpen && <Modal onClose={closeModal} />} */}
            <Navbar.Brand href="/">
              <img src={Logo} alt="" />
            </Navbar.Brand>
            {!istoggle && (
              <Navbar.Collapse
                id="basic-navbar-nav"
                ref={collapseRef}
                className="bigsc"
              >
                <Nav
                  className="me-auto"
                  style={{ gap: "17px", margin: "auto", maxHeight: "40px" }}
                >
                  <Link
                    to="/"
                    style={{
                      textDecoration: "none",
                      borderBottom: isHome && "1px solid #302363",
                    }}
                    className="links"
                    onClick={hideBars}
                  >
                    Home
                  </Link>
                  <Link
                    to="/why-coding-school"
                    style={{
                      textDecoration: "none",
                      borderBottom: isCs && "1px solid #302363",
                    }}
                    className="links"
                    onClick={hideBars}
                  >
                    Coding school
                  </Link>
                  <MenuWrap
                    style={{
                      borderBottom: isOurProgram && "1px solid #302363",
                    }}
                  >
                    <ul className="menu" style={{ padding: "0" }}>
                      <MenuItem>
                        <span
                          className="links"
                          style={{ display: "flex", gap: "10px" }}
                          onMouseEnter={handleMouseEnter}
                          onMouseLeave={handleMouseLeave}
                        >
                          <span>Our Programs</span>
                          <img
                            src={arrow}
                            alt=""
                            style={{
                              transform: isHovered
                                ? "rotate(0deg)"
                                : "rotate(180deg)",
                              transition: "transform 0.3s ease-in-out",
                            }}
                          />
                        </span>
                        <DropMenu
                          className="drop-menu"
                          style={{ minHeight: "auto" }}
                          onMouseEnter={handleMouseEnter}
                          onMouseLeave={handleMouseLeave}

                        >
                          <Link
                            to="/robotics-for-kids"
                            style={{ textDecoration: "none" }}
                          >
                            <DropMenuItem
                              onClick={RhoboDropdownHandler}
                              style={{
                                background: robo ? "#EC285B" : "",
                                maxHeight: "92px",
                                minHeight: "auto",
                              }}
                            >
                              <span
                                className="droplinks"
                                style={{ color: robo ? "#fff" : "" }}
                                // isAbout={isAbout}
                              >
                                Robo Whiz
                              </span>
                            </DropMenuItem>
                          </Link>
                          <Link
                            to="/game-development-for-kids"
                            style={{ textDecoration: "none" }}
                          >
                            <DropMenuItem
                              onClick={GamingDropdownHandler}
                              style={{
                                background: gaming ? "#EC285B" : "",
                                maxHeight: "92px",
                                minHeight: "auto",
                              }}
                            >
                              <span
                                className="droplinks"
                                style={{ color: gaming ? "#fff" : "" }}
                              >
                                Gaming Honcho
                              </span>
                            </DropMenuItem>
                          </Link>
                          <Link
                            to="/champ-coder"
                            style={{ textDecoration: "none" }}
                          >
                            <DropMenuItem
                              onClick={ChampDropdownHandler}
                              style={{
                                background: champ ? "#EC285B" : "",
                                maxHeight: "92px",
                                minHeight: "auto",
                              }}
                            >
                              <span
                                className="droplinks"
                                style={{ color: champ ? "#fff" : "" }}
                              >
                                Champ Coder
                              </span>
                            </DropMenuItem>
                          </Link>
                          <Link
                            to="/artificial-intelligence-programs-for-kids"
                            style={{ textDecoration: "none" }}
                          >
                            <DropMenuItem
                              onClick={MetevDropdownHandler}
                              style={{
                                background: metev ? "#EC285B" : "",
                                maxHeight: "92px",
                                minHeight: "auto",
                              }}
                            >
                              <span
                                className="droplinks"
                                style={{ color: metev ? "#fff" : "" }}
                              >
                                Metaverse Maestro
                              </span>
                            </DropMenuItem>
                          </Link>
                        </DropMenu>
                      </MenuItem>
                    </ul>
                  </MenuWrap>
                  <Link
                    to="/contact-us"
                    style={{
                      textDecoration: "none",
                      borderBottom: isContactUS && "1px solid #302363",
                    }}
                    className="links"
                    onClick={hideBars}
                  >
                    Contact Us
                  </Link>
                </Nav>
              </Navbar.Collapse>
            )}
            <div className="toggle">
              <Button className="button1" onClick={openModal} style={{width:navcollpase && '150px'}}>
                Book Free Class
              </Button>
              <Button className="button1" onClick={handleBookFreeClassClick} style={{width:navcollpase && '150px'}}>
                Join Now
              </Button>
              <Navbar.Toggle
                aria-controls="basic-navbar-nav"
                style={{ border: "none" }}
              >
                <img src={Toggle} alt="" />
              </Navbar.Toggle>
            </div>
          </>
        )}
        {isMobile && (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Navbar.Brand href="/">
                <img src={Logo} alt="" style={{ maxWidth: "140px" }} />
              </Navbar.Brand>

              <Navbar.Toggle
                aria-controls="basic-navbar-nav"
                style={{ border: "none" }}
              >
                <img src={Toggle} alt="" />
              </Navbar.Toggle>
            </div>
            <div className="toggle" style={{ margin: "1rem auto" }}>
              <Button className="button1" onClick={openModal}>
                Book Free Class
              </Button>
              <Button className="button1" onClick={handleBookFreeClassClick}>
                Join Now
              </Button>
            </div>
          </>
        )}
        {istoggle && (
          <Navbar.Collapse id="basic-navbar-nav" ref={collapseRef}>
            <Nav className="me-auto">
              <Link
                to="/"
                style={{ textDecoration: "none" }}
                className="links"
                onClick={hideBars}
              >
                Home
              </Link>
              <Link
                to="/why-coding-school"
                style={{ textDecoration: "none" }}
                className="links"
                onClick={hideBars}
              >
                Coding school
              </Link>
              {/* <Link
                to="/our-programs"
                style={{ textDecoration: "none" }}
                className="links"
                onClick={hideBars}
              >
                Our Programs
              </Link> */}
              <MenuWrap>
                <ul className="menu" style={{ padding: "0" }}>
                  <MenuItem>
                    <span className="links">Our Programs</span>
                    <DropMenu
                      className="drop-menu"
                      style={{ minHeight: "auto" }}
                    >
                      <Link
                        to="/robotics-for-kids"
                        style={{ textDecoration: "none" }}
                      >
                        <DropMenuItem
                          onClick={RhoboDropdownHandler}
                          style={{
                            background: robo ? "#EC285B" : "",
                            maxHeight: "92px",
                            minHeight: "auto",
                          }}
                        >
                          <span
                            className="droplinks"
                            style={{ color: robo ? "#fff" : "" }}
                            // isAbout={isAbout}
                          >
                            Robo Whiz
                          </span>
                        </DropMenuItem>
                      </Link>
                      <Link
                        to="/game-development-for-kids"
                        style={{ textDecoration: "none" }}
                      >
                        <DropMenuItem
                          onClick={GamingDropdownHandler}
                          style={{
                            background: gaming ? "#EC285B" : "",
                            maxHeight: "92px",
                            minHeight: "auto",
                          }}
                        >
                          <span
                            className="droplinks"
                            style={{ color: gaming ? "#fff" : "" }}
                          >
                            Gaming Honcho
                          </span>
                        </DropMenuItem>
                      </Link>
                      <Link
                        to="/champ-coder"
                        style={{ textDecoration: "none" }}
                      >
                        <DropMenuItem
                          onClick={ChampDropdownHandler}
                          style={{
                            background: champ ? "#EC285B" : "",
                            maxHeight: "92px",
                            minHeight: "auto",
                          }}
                        >
                          <span
                            className="droplinks"
                            style={{ color: champ ? "#fff" : "" }}
                          >
                            Champ Coder
                          </span>
                        </DropMenuItem>
                      </Link>
                      <Link
                        to="/artificial-intelligence-programs-for-kids"
                        style={{ textDecoration: "none" }}
                      >
                        <DropMenuItem
                          onClick={MetevDropdownHandler}
                          style={{
                            background: metev ? "#EC285B" : "",
                            maxHeight: "92px",
                            minHeight: "auto",
                          }}
                        >
                          <span
                            className="droplinks"
                            style={{ color: metev ? "#fff" : "" }}
                          >
                            Metaverse Maestro
                          </span>
                        </DropMenuItem>
                      </Link>
                    </DropMenu>
                  </MenuItem>
                </ul>
              </MenuWrap>
              <Link
                to="/contact-us"
                style={{ textDecoration: "none" }}
                className="links"
                onClick={hideBars}
              >
                Contact Us
              </Link>
            </Nav>
          </Navbar.Collapse>
        )}
      </Container>
    </Navbar>
  );
}

export default NavBar;
// css
const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 11111111;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 2rem;
  background-color: rgba(0, 0, 0, 0.5);
  @media (max-width: 1024px) {
    /* margin-top: 6rem; */
  }
`;

const ModalContent = styled.div`
  position: relative;
`;
const CloseButton = styled.button`
  margin-top: 10px;
  border: none;
  background: red;
  color: #fff;
  position: absolute;
  top: -13%;
  right: -4%;
`;
// dropdown
const MenuWrap = styled.div`
  /* padding-top: 8px; */
  .menu,
  .drop-menu {
    list-style-type: none;
    /* padding: 0; */
    /* margin: 0; */
    @media (max-width: 1024px) {
      padding: 0;
    }
  }
`;

const MenuItem = styled.li`
  display: inline-block;
  position: relative;

  a {
    font-family: "Metropolis";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    border-bottom: ${(props) =>
      props.isHome
        ? "4px solid #F8AC17"
        : props.isMc
        ? "4px solid #F8AC17"
        : props.isAbout
        ? "4px solid #F8AC17"
        : ""};
    color: #303030;
    &:hover {
      font-weight: 700;
    }
    @media (min-width: 1024px) {
      &:hover::after {
        width: 100%;
      }
    }
    @media (min-width: 991px) {
      color: black;
    }
    @media (max-width: 991px) {
      color: #ffffff;
    }
  }

  &:hover .drop-menu {
    display: block;
  }
`;

const DropMenu = styled.ul`
  display: none;
  position: absolute;
  background-color: #fff;
  min-width: 100px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.31);
  border-radius: 11px;
  width: 229px;
  z-index: 123;
  padding: 10px 0;
`;

const DropMenuItem = styled.li`
  width: 90%;
  margin: 5px auto 0;
  height: 47px;
  /* background-color: ${(props) =>
    props.cd === "true" ? "#F8AC17" : "red"}; */
  border-radius: 6px;
  z-index: 123;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;
  &:hover {
    background-color: #ec285b;
    span {
      color: #fff;
    }
  }

  a {
    font-family: "Metropolis";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;

    color: #303030;
  }
  img {
    padding: 0 10px 0 0;
  }
`;
